<template>
  <div :class="{ 'custom-artical-page': isMobile }">
    <Header></Header>
    <div class="wrapper">
      <div v-if="isMobile === false" class="centerWidth article-box">
        <div v-if="ad" class="ad-box">
          <a :href="ad[trans('url,urlEn')]" target="_blank" :title="ad[trans('name,titleEn')]">
            <img
              class="detailBg"
              :src="
                ad[trans('img,imgEn')] ? baseURL + ad[trans('img,imgEn')] : ''
              "
              alt=""
            />
          </a>
        </div>
        <div class="article-content-box">
          <div class="sublevel-share">
            <div class="share-box">
              <div class="left">
                <el-divider class="custom-divider">{{ $t('common.share') }}</el-divider>
                <el-popover
                  placement="right"
                  trigger="click">
                  <div class="wechat-tip">
                  <p>{{ $t('common.wxShareTip') }}</p>
                    <el-image
                    style="width: 100px; height: 100px"
                    :src="qrUrl"></el-image>
                  </div>
                  <div slot="reference" class="weixin custom-icon"></div>
                </el-popover>
                
              </div>
              <div class="left">
                <el-divider>{{ $t('common.collect') }}</el-divider>
                <div class="new-collect custom-icon" :class="{ 'collected': detail.isCollect }" @click="collectHandle"></div>
              </div>
              <div class="left">
                <el-divider>{{ $t('common.like') }}</el-divider>
                <div class="like custom-icon" :class="{'collected': +detail.iskouds, 'has-num': detail.koudsNum }" @click="likeHandle">
                  <i v-if="detail.koudsNum">{{ (detail.koudsNum + '').length > 3 ? '999+' : detail.koudsNum }}</i>
                </div>
              </div>
            </div>
          </div>
          <div class="article-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item
                ><a href="/" target="_blank">{{
                  $t("common.webName")
                }}</a></el-breadcrumb-item
              >
              <el-breadcrumb-item
                ><a :href="'/' + $route.meta.mark">{{
                  cateCrumb ? cateCrumb[trans('name,nameEn')] : ''
                }}</a></el-breadcrumb-item
              >
              <el-breadcrumb-item>{{
                $t("common.article")
              }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div>
              <p class="detailT">{{ detail[trans("title,titleEn")] }}</p>
              <p class="detailS">{{ detail.createTime }}</p>
            </div>
            <div style="margin-top: 20px">
              <div class="detailContent">
                <div
                  v-html="detail[trans('content,contentEn')]"
                  style="line-height: 2"
                ></div>
              </div>
            </div>
            <div
              style="display: flex; margin-top: 10px"
              v-if="detail[trans('label,labelEn')]"
            >
              <p
                style="
                  margin-right: 20px;
                  font-size: 14px;
                  font-weight: 700;
                  color: #8a9099;
                  line-height: 20px;
                "
              >
                {{ $t('common.label') }}:
              </p>
              <div v-if="detail[trans('label,labelEn')]">
                <span
                  class="label-box"
                  v-for="(label, labelIndex) in detail[trans('label,labelEn')]
                    ? detail[trans('label,labelEn')].split(',')
                    : []"
                  :key="labelIndex"
                  >{{ label }}</span
                >
              </div>
            </div>
            <div v-if="detail.next">
              <div class="dianzan" :class="{ 'liked': detail.iskouds }" @click="likeHandle">
                <div class="dianzanBg"></div>
                <p class="dianzanT">{{ detail.koudsNum > 0 ? detail.koudsNum : $t('newsDetail.firstLike') }}</p>
              </div>
              <p class="dianzanTitle">{{ $t('newsDetail.likeTip') }}</p>
            </div>
            <div class="next">
              <p class="nextT">{{ $t('newsDetail.next') }}</p>
              <p class="nextBottom"></p>
            </div>
            <div
              v-if="detail.next"
              @click="toDetail(detail.next.id)"
              style="
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                margin-bottom: 20px;
                cursor: pointer;
              "
            >
              <div>
                <img :src="detail.next[trans('img,imgEn')] ? baseURL + detail.next[trans('img,imgEn')] : ''" alt="" style="width: 210px; height: 140px" />
              </div>

              <div style="width: 470px">
                <p class="nextT1">{{detail.next[trans('title,titleEn')]}}</p>
                <p class="nextT2 twoLine" v-html="detail.next[trans('content,contentEn')]"></p>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                  "
                >
                  <div>
                    <span class="label-box" v-for="(label, labelIndex) in (detail.next[trans('label,labelEn')] ? detail.next[trans('label,labelEn')].split(',') : [])"
                      :key="labelIndex">{{label}}</span>
                  </div>
                  <div>
                    <p
                      style="font-size: 12px; line-height: 20px; color: #a1a8b3"
                    >
                    {{ detail.next.createTime }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Right :modules="rightModules"></Right>
        </div>

        <!--      文章标题-->
        <!-- <div
            style="
              text-align: center;
              width: 100%;
              margin-top: 2%;
              font-size: 18px;
              font-weight: bold;
            "
          >
            <div v-html="detailTitle"></div>
          </div> -->
        <!-- <div
            style="
              width: 100%;
              font-size: 16px;
              color: gray;
              display: flex;
              margin: 15px 0 8px 0;
            "
          >
            <div style="text-align: right; width: 52%; margin-top: 5px">
              {{ detailTime }}
            </div>
            <div style="width: 39%; display: flex; justify-content: flex-end">
              <el-button
                v-if="collect == '0'"
                class="collect"
                @click="collectChange()"
              >
              </el-button>
              <el-button
                v-if="collect == '1'"
                class="collectReally"
                @click="collectChange()"
              >
              </el-button>

    
              <el-button
                v-if="translateThis === 0"
                class="translateThis"
                @click="translate()"
              >
              </el-button>
              <el-button
                v-if="translateThis === 1"
                class="translateThisReally"
                @click="translate()"
              >
              </el-button>
            </div>
          </div> -->

        <!-- <div class="detailContent">
            <div v-html="detailContent" style="line-height: 2"></div>
          </div> -->

        <!-- <div
            v-if="trans === true"
            style="display: flex; margin-left: 2.5%; margin-top: 4%; width: 95%"
          >
            <dv-border-box-10 :color="['white', 'var(--main-color)']">
              <div class="transborder" id="container1">
                <div
                  style="
                    justify-content: center;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 2%;
                    font-size: 18px;
                    font-weight: bold;
                  "
                >
                  <div v-html="detailTitle"></div>
                </div>
                <div
                  style="
                    justify-content: center;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-size: 14px;
                    color: gray;
                    display: flex;
                    margin: 15px 0 8px 0;
                  "
                >
                  <div style="text-align: right; width: 52%">
                    {{ detailTime }}
                  </div>
                  <div
                    style="width: 35%; display: flex; justify-content: flex-end"
                  >
                    <el-button
                      v-if="collect == '0'"
                      class="collect"
                      @click="collectChange()"
                    >
                    </el-button>
                    <el-button
                      v-if="collect == ' 1'"
                      class="collectReally"
                      @click="collectChange()"
                    >
                    </el-button>

                    <el-button
                      v-if="translateThis === 0"
                      class="translateThis"
                      @click="translate()"
                    >
                    </el-button>
                    <el-button
                      v-if="translateThis === 1"
                      class="translateThisReally"
                      @click="translate()"
                    >
                    </el-button>
                  </div>
                </div>

                <div class="detailContent">
                  <div
                    v-html="detailContent"
                    style="line-height: 2; text-align: justify"
                  ></div>
                </div>
              </div>
            </dv-border-box-10>
            <div class="fload" @click="isNEWs = 0"></div>
            <dv-border-box-10 :color="['white', 'var(--main-color)']">
              <div class="transborder" id="container2">
                <div
                  style="
                    text-align: center;
                    justify-content: center;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 2%;
                    font-size: 18px;
                    font-weight: bold;
                  "
                >
                  {{ translatedetailTitle }}
                </div>
                <div
                  v-if="this.translatedetailTitle"
                  style="
                    width: 100%;
                    font-size: 14px;
                    color: gray;
                    display: flex;
                    margin: 8px 0 8px 0;
                  "
                >
                  <div
                    style="
                      justify-content: center;
                      height: 30px;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      width: 100%;
                    "
                  >
                    {{ detailTime }}
                  </div>
                </div>

                <div class="detailContent">
                  <div v-if="this.translatedetaiContent">
                    <div
                      v-html="this.translatedetaiContent"
                      style="line-height: 2; text-align: justify"
                      class="detail"
                    ></div>
                  </div>
                  <div v-else>
                    <dv-loading
                      style="
                        line-height: 2;
                        color: var(--main-color);
                        margin-top: 20vh;
                      "
                      class="detail"
                    >
                      Loading...
                    </dv-loading>
                  </div>
                </div>
              </div>
            </dv-border-box-10>
          </div> -->
        <AiPop :news_id="newsId"></AiPop>
      </div>
      <div v-if="isMobile === true">
        <div class="m-info-detail-box">
          <div class="ADHeader">
            <img
              v-if="detail[trans('img,imgEn')]"
              :src="baseURL + detail[trans('img,imgEn')]"
              alt=""
            />
            <p class="ADT1 twoLine">{{ detail[trans("title,titleEn")] }}</p>
            <p class="ADT2">{{ detail.createTime }}</p>
            <p class="ADContent" v-html="detail[trans('content,contentEn')]"></p>
            <div class="ADBox" v-if="detail[trans('label,labelEn')]">
              <p v-for="(label, labelIndex) in detail[trans('label,labelEn')]
                  ? detail[trans('label,labelEn')].split(',')
                  : []"
                :key="labelIndex">{{ label }}</p>
            </div>
            <div class="shareBox">
              <div class="" @click="shareToWechat">
                <img
                  src="../../assets/article_chat.png"
                  alt=""
                />
                <span>{{ $t('newsDetail.wx') }}</span>
              </div>
              <div @click="shareToMoments">
                <img
                  src="../../assets/article_friend.png"
                  alt=""
                />
                <span>{{ $t('newsDetail.wxFriend') }}</span>
              </div>
            </div>
          </div>

          <MRight :modules="mRightModules"></MRight>

          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              ><a href="/" target="_blank">{{
                $t("common.webName")
              }}</a></el-breadcrumb-item
            >
            <el-breadcrumb-item
              ><a :href="'/' + $route.meta.mark">{{
                cateCrumb ? cateCrumb[trans('name,nameEn')] : ''
              }}</a></el-breadcrumb-item
            >
            <el-breadcrumb-item>{{
              $t("common.article")
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <ScrollTop></ScrollTop>
        <AiPop :news_id="newsId"></AiPop>

        <van-tabbar @change="mBTabbarChangeHandle" v-model="mBTabActive">
          <van-tabbar-item icon="wap-home-o"></van-tabbar-item>
          <van-tabbar-item :icon="+detail.iskouds ? 'good-job' : 'good-job-o'" :badge="detail.koudsNum > 0 ? detail.koudsNum : '赞'"></van-tabbar-item>
          <van-tabbar-item icon="share-o"></van-tabbar-item>
          <van-tabbar-item :icon="detail.isCollect ? 'bookmark' : 'bookmark-o'"></van-tabbar-item>
        </van-tabbar>

        <ShareTip ref="shareTip" :type="shareType" v-show="shareVisible" @close="closeShareHandle"></ShareTip>

        <!-- <div class="list1" v-if="languageSelect === 'zh'">
          <div v-if="trans === false">
           >
            <div
              style="
                text-align: center;
                width: 100%;
                margin-top: 2%;
                font-size: 18px;
                font-weight: bold;
              "
            >
              <div v-html="detailTitle"></div>
            </div>
            <div
              style="
                width: 100%;
                font-size: 16px;
                color: gray;
                display: flex;
                margin: auto 0;
              "
            >
              <div style="text-align: center; width: 100%; margin-top: 5px">
                {{ detailTime }}
              </div>
              <div
                style="
                  position: absolute;
                  width: 89%;
                  display: flex;
                  justify-content: flex-end;
                "
              >
                <el-button
                  v-if="collect == '0'"
                  class="collect"
                  @click="collectChange()"
                >
                </el-button>
                <el-button
                  v-if="collect == '1'"
                  class="collectReally"
                  @click="collectChange()"
                >
                </el-button>
              </div>
            </div>

            
            <div class="detailContent">
              <div v-html="detailContent" style="line-height: 2"></div>
            </div>
          </div>
          <div
            v-if="trans === true"
            style="display: flex; margin-left: 2.5%; margin-top: 4%; width: 95%"
          >
            <dv-border-box-10 :color="['white', 'var(--main-color)']">
              <div class="transborder" id="container1">
                <div
                  style="
                    justify-content: center;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 2%;
                    font-size: 18px;
                    font-weight: bold;
                  "
                >
                  <div v-html="detailTitle"></div>
                </div>
                <div
                  style="
                    justify-content: center;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-size: 14px;
                    color: gray;
                    display: flex;
                    margin: 15px 0 8px 0;
                  "
                >
                  <div style="text-align: right; width: 52%">
                    {{ detailTime }}
                  </div>
                  <div
                    style="width: 35%; display: flex; justify-content: flex-end"
                  >
                    <el-button
                      v-if="collect == '0'"
                      class="collect"
                      @click="collectChange()"
                    >
                    </el-button>
                    <el-button
                      v-if="collect == '1'"
                      class="collectReally"
                      @click="collectChange()"
                    >
                    </el-button>
                    <el-button
                      v-if="translateThis === 0"
                      class="translateThis"
                      @click="translate()"
                    >
                    </el-button>
                    <el-button
                      v-if="translateThis === 1"
                      class="translateThisReally"
                      @click="translate()"
                    >
                    </el-button>
                  </div>
                </div>
                <div class="detailContent">
                  <div
                    v-html="detailContent"
                    style="line-height: 2; text-align: justify"
                  ></div>
                </div>
              </div>
            </dv-border-box-10>
            <div class="fload" @click="isNEWs = 0"></div>
            <dv-border-box-10 :color="['white', 'var(--main-color)']">
              <div class="transborder" id="container2">
                <div
                  style="
                    text-align: center;
                    justify-content: center;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 2%;
                    font-size: 18px;
                    font-weight: bold;
                  "
                >
                  {{ translatedetailContent }}
                </div>
                <div
                  v-if="this.translatedetailContent"
                  style="
                    width: 100%;
                    font-size: 14px;
                    color: gray;
                    display: flex;
                    margin: 8px 0 8px 0;
                  "
                >
                  <div
                    style="
                      justify-content: center;
                      height: 30px;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      width: 100%;
                    "
                  >
                    {{ detailTime }}
                  </div>
                </div>
                <div class="detailContent">
                  <div v-if="this.translatedetaiContent">
                    <div
                      v-html="this.translatedetailContent"
                      style="line-height: 2; text-align: justify"
                      class="detail"
                    ></div>
                  </div>
                  <div v-else>
                    <dv-loading
                      style="
                        line-height: 2;
                        color: var(--main-color);
                        margin-top: 20vh;
                      "
                      class="detail"
                    >
                      Loading...
                    </dv-loading>
                  </div>
                </div>
              </div>
            </dv-border-box-10>
          </div>
        </div>
        <div class="list1" v-if="languageSelect === 'en'">
          <div v-if="trans === false">
            
            <div
              style="
                text-align: center;
                width: 100%;
                margin-top: 2%;
                font-size: 18px;
                font-weight: bold;
              "
            >
              <div v-html="titleEn"></div>
            <div
              style="
                width: 100%;
                font-size: 16px;
                color: gray;
                display: flex;
                margin: 15px 0 8px 0;
              "
            >
              <div style="text-align: right; width: 52%; margin-top: 5px">
                {{ detailTime }}
              </div>
            </div>
            <div class="detailContent">
              <div
                v-html="contentEn"
                style="line-height: 2; text-align: justify"
              ></div>
            </div>
          </div>
        </div>
        <AiAbstract :news_id="newsId"></AiAbstract> -->
      </div>
    </div>

    <Footer v-if="isMobile === false"></Footer>
    <Bottom v-if="isMobile === true">123</Bottom>
    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideInfoDialog"
    ></InfoTip>
  </div>
</template>

<script>
// import AiPop from "@/components/AiPop.vue";
// import md5 from "js-md5";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
import LoginTip from "@/components/LoginTip.vue";
import InfoTip from "@/components/InfoTip.vue";
import Right from "@/components/Right.vue";
import ScrollTop from "@/components/mobile/ScrollTop.vue";
import Bottom from "@/components/mobile/Bottom.vue";
import MRight from "@/components/mobile/Right.vue";
import { Tabbar, TabbarItem } from 'vant';
import ShareTip from "@/components/mobile/ShareTip.vue";
import QRCode from 'qrcode';

import mixins from "@/utils/mixins.js";

import { getAds, getNewsDetail, collectFetch, likeFetch } from "@/api/index";

import { mapState } from 'vuex'
import AiPop from "../../components/AiPop.vue";

const map = {
  1: 4,
  4: 3
}

export default {
  name: "articalDetail",
  mixins: [mixins],
  props: {
    // eslint-disable-next-line no-undef
    newId: Number,
  },

  components: {
    ScrollTop,
    Bottom,
    InfoTip,
    LoginTip,
    // BottomBanner,
    Footer,
    Header,
    AiPop,
    Right,
    MRight,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    ShareTip,
  },
  watch: {
    shareVisible(newVal){
      if(newVal && this.isMobile){
        document.body.style.overflow = 'hidden';
      }else{
        document.body.style.overflow = 'initial';
      }
    }
  },
  data() {
    return {
      shareUrl: 'https://example.com/path/to/share',
      HTMLtranslatedetailTitle: "",
      dialogVisibleInfo: false,
      dialogVisible: false,
      isMobile: true,
      languageSelect: "",
      translateThis: 1,
      maxTextLength: 100, // 设置最大字数
      searchKeywords: "",
      seconderyClassfy: [],
      secondaryTypeid: "",
      isLoading: "",
      articles: [],
      hovered: false,
      content: "",
      size: 8,
      page: 1,
      total: 1,
      change: 0,
      // trans: false,
      collect: 0,
      translatedetaiContent: "",
      detailTitle: "",
      detailTime: "",
      detailContent: "",
      translatedetailTitle: "",
      zhStr: "",
      enStr: "",
      salt: parseInt(Math.random() * 1000000000), //随机数
      appid: "20231016001849033",
      userkey: "ZgPv1FfEEWXPsNH0HzWl",
      accessToken: "",
      fanyi: "",
      q: "",
      token: localStorage.getItem("access_token"),
      userId: localStorage.getItem("userId"),
      buttonStyle: false, // 控制按钮样式的变量
      activeIndex: null, // 记录被点击的按钮索引
      AK: "P4qj3AkttTc6qdQGXOPorEMi",
      SK: "dBf1hilVcWZbqkooGzYvgVydCOf4LAGY",
      titleEn: "",
      contentEn: "",
      scanId: "",
      isView: "",
      isWhole: "",
      user: this.$store.state.user,


      newsId: "",
      ad: "",
      detail: "",
      rightModules: [
        {
          type: "technology",
          path: 'news',
          title: this.$t('right.latestNews'),
          dataNum: 5,
          primaryTypeId: 4,
        },
        {
          type: "report",
          path: 'report',
          title: this.$t('right.latestReport'),
          dataNum: 5,
          primaryTypeId: 2,
        },
      ],
      qrUrl: '',
      mRightModules: [
        {
          type: 'news',
          path: 'technology',
          title: this.$t('newsDetail.relateNews'),
          dataNum: 5,
          primaryTypeId: 1
        },
        {
          type: 'report',
          path: 'report',
          title: this.$t('right.latestRJ'),
          dataNum: 4,
          primaryTypeId: 2
        },
      ],
      mBTabActive: -1,
      shareType: 'web',
      shareVisible: false
    };
  },
  computed: {
    cateCrumb(){
      let temp = this.navCates.find(item => item.bannerId == map[this.detail.primaryTypeid])
      return temp
    },
    ...mapState({
      'access_token': 'access_token', 
      'userInfo': 'user',
      'navCates': 'navList'
    })
  },
  async created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    // console.log(this.$store)
    window.scrollTo(0, 0);
    // this.newsId = this.$route.query.newId;
    // this.scanId = this.$route.query.Typeid;
    // if (this.newId) {
    //   this.newsId = this.newId;
    // }
    var page = {
      setPage: this.$route.query.currentPage,
      primaryTypeid: this.$route.query.Typeid,
      isFlag: this.$route.query.isFlag,
    };
    localStorage.setItem("setPage", JSON.stringify(page));


    this.newsId = this.$route.params.id;
    await this.getNewsDetail(this.newsId);
    this.setTKD(this.detail);
    this.generateQR();
    this.getAds();

    // document.title = localStorage.getItem("Title");
    // console.log(this.$route.query.newId)
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: this.detail.title,
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 2000);

    // this.loginView();
  },
  mounted() {
    // this.baidutrans();
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    shareToMoments() {
      // 检查是否在微信客户端
      if (this.$refs.shareTip.isWxEnv()) {
        this.shareType = 'friend'
      } else {
        this.shareType = 'web'
      }
      this.shareVisible = true
    },
    shareToWechat() {
      // 检查是否在微信客户端
      if (this.$refs.shareTip.isWxEnv()) {
        this.shareType = 'chat'
      } else {
        this.shareType = 'web'
      }
      this.shareVisible = true
    },
    async getNewsDetail(id) {
      let res = await getNewsDetail(id, {
        lang: this.$i18n.locale
      });

      console.log(res)

      this.detail = res.data;
      this.detail.next[this.trans('content,contentEn')] = this.setText((res.data.next && res.data.next[this.trans('content,contentEn')]) || '').substr(0, 150);
    },
    async getAds() {
      let res = await getAds({
        positions: "8",
      });

      this.ad = res.data[0];
    },
    toDetail(id){
      this.$router.push('/'+ this.$route.meta.mark +'/' + id);
    },
    async collectHandle(){
      if(this.userInfo.userId){
        if(this.detail.isCollect){
          this.$confirm(this.$t('extends.collectTip'), this.$t('ai.tipTitle'), {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning'
          }).then(async () => {
            await this.collectFetch()
            this.$message({
              type: 'success',
              message: this.$t('extends.rejectCollect')
            });
          }).catch(() => {});
        }else{
          await this.collectFetch()
          this.$message({
            type: 'success',
            message: this.$t('extends.resolveCollect')
          });
        }
      }else{
        this.dialogVisible = true;
      }
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    async collectFetch(){
      let res = await collectFetch({
        userId: this.userInfo.userId,
        newsId: this.detail.id,
      })
      this.$set(this.detail, 'isCollect', +res.data.isCollect);
    },
    async generateQR(){
      let url = location.href
      try {
        const opts = {
          errorCorrectionLevel: 'H',
          type: 'image/png',
          quality: 0.9,
          margin: 1,
        };
        this.qrUrl = await QRCode.toDataURL(url, opts);
      } catch (err) {
        console.error(err);
      }
    },
    async likeHandle(){
      if(this.userInfo.userId){
        if(this.detail.iskouds){
          this.$confirm(this.$t('extends.likeTip'), this.$t('ai.tipTitle'), {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.confirm'),
            type: 'warning'
          }).then(async () => {
            await this.likeFetch()
            this.$message({
              type: 'success',
              message: this.$t('extends.rejectLike')
            });
          }).catch(() => {});
        }else{
          await this.likeFetch()
          this.$message({
            type: 'success',
            message: this.$t('extends.resolveLike')
          });
        }
      }else{
        this.dialogVisible = true;
      }
    },
    async likeFetch(){
      let res = await likeFetch({
        userId: this.userInfo.userId,
        newsId: this.detail.id,
      })
      this.$set(this.detail, 'iskouds', +res.data.isKouds);
      if(+res.data.isKouds){
        this.$set(this.detail, 'koudsNum', this.detail.koudsNum+1);
      }else{
        this.$set(this.detail, 'koudsNum', this.detail.koudsNum-1);
      }
    },
    setTKD(detail){
      let title = (detail[this.trans("title,titleEn")] + '-' + this.$t(this.$route.meta.title));

      document.title = title;
      document.querySelector('meta[property="og:title"]').content = title;

      document.querySelector('meta[property="og:keywords"]').content = this.$t(this.$route.meta.keywords)
      document.querySelector('meta[property="og:description"]').content = detail[this.trans("content,contentEn")] ? this.setText(detail[this.trans("content,contentEn")]).substr(0, 100) : this.$t('common.defaultdDscription');
    },
    mBTabbarChangeHandle(active){
      switch(active){
        case 0: 
          this.$router.push('/');
          break;
        case 1: 
          this.likeHandle();
          this.mBTabActive = -1;
          break;
        case 2: 
          // this.$router.push('/');
          this.shareVisible = true
          this.mBTabActive = -1;
          break;
        case 3: 
          this.collectHandle();
          this.mBTabActive = -1;
          break;
        default: 
          this.mBTabActive = -1;
      }
    },
    closeShareHandle(){
      this.shareVisible = false
    },

    hideInfoDialog() {
      this.dialogVisibleInfo = false;
    },
    loginView() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 1,
            categoryId: this.scanId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.isView = res.data.data[0].isView;
          this.isWhole = res.data.data[0].isWhole;
          // 不需要登录
          if (this.isView === "1") {
            if (this.languageSelect === "zh") {
              this.getartical();
            } else {
              this.getArticalEn();
            }
          }
          // 需要登录
          else {
            // 已经登
            console.log(this.user);
            console.log(this.userId);
            if (this.user) {
              // 无需完善
              if (this.isWhole === "1") {
                if (this.languageSelect === "zh") {
                  this.getartical();
                } else {
                  this.getArticalEn();
                }
              }
              // 需要完善
              else {
                if (this.$store.state.typeInfo == 0) {
                  this.dialogVisibleInfo = true;
                } else {
                  if (this.languageSelect === "zh") {
                    this.getartical();
                  } else {
                    this.getArticalEn();
                  }
                }
              }
            } else {
              this.dialogVisible = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getElementHeight(id) {
      const element = document.getElementById(id);
      if (element) {
        return element.offsetHeight;
      }
      return null; // or any other value to handle when element is not found
    },
    setElementHeight(id, height) {
      const element = document.getElementById(id);
      if (element) {
        element.style.height = `${height}px`;
      }
    },
    //收藏
    collectChange() {
      this.$axios
        .post("/api/collect/addOrDelete", {
          // params: {
          userId: this.user.userId,
          newsId: this.newsId,
          // },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log(res.data.data.isCollect);
          this.collect = res.data.data.isCollect;
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 翻译
    async translate() {
      if (this.translateThis === 1) {
        this.translateThis = 0;
        this.trans = true;
      } else {
        this.translateThis = 1;
        this.trans = false;
      }
      /* 从页面获取选择的目标语言 传入url */
      this.to = "en";
      /* 对待翻译字符做url编码 */
      this.from = "zh";

      this.$axios
        .post(
          "/api/enes/translateByTitle",
          {
            newsId: this.newsId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          this.HTMLtranslatedetailTitle = res.data.data;
          this.translatedetailTitle = this.removeHtmlTags(
            this.HTMLtranslatedetailTitle
          );
        })
        .catch((error) => {
          console.log(error);
        });

      this.$axios
        .post(
          "/api/enes/translateByContent",
          {
            newsId: this.newsId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          this.translatedetaiContent = res.data.data;

          // 假设你的IP地址是 ipAddress，替换成你的IP地址
          const ipAddress = "https://www.oilgasinfoai.com";

          // 用正则表达式来匹配图像标签的src属性
          const imgRegex = /<img\s[^>]*src="([^"]*)"[^>]*>/gi;

          // 将IP地址添加到图像标签的src属性前，并添加样式
          this.translatedetaiContent = this.translatedetaiContent.replace(
            imgRegex,
            (match, src) => {
              let imgSrc = src.indexOf("http") > -1 ? src : ipAddress + src;
              return `<img style="display: block; width: 40%; height: auto; object-fit: contain; background-size: contain; background-position: center; background-repeat: no-repeat; margin: 0.1% 30% 1% 30%; box-shadow: 5px 5px 5px #888888;" src="${imgSrc}" />`;
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });

      // 延迟执行一个函数，延迟时间为5000毫秒（5秒）
      setTimeout(() => {
        // 在这里放置希望延迟执行的代码
      }, 5000);
    },

    removeHtmlTags(input) {
      return input.replace(/<[^>]*>/g, "");
    },

    baidutrans() {
      this.$nextTick(() => {
        const container1Height = this.getElementHeight("container1");
        const container2Height = this.getElementHeight("container2");
        if (container1Height && container2Height) {
          const maxHeight = Math.max(container1Height, container2Height);
          this.setElementHeight("container1", maxHeight);
          this.setElementHeight("container2", maxHeight);
        }
      });
    },

    getartical() {
      // console.log("新闻"+this.newsId)
      this.$axios
        .get("/api/news/" + this.newsId, {
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          this.articles = res.data.data; // 将响应中的数据直接赋值给 articles 对象数组
          // 重新生成列表
          this.list();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("/api/news/getNews/list", {
          params: {
            primaryTypeid: this.primaryTypeid,
            secondaryTypeid: this.secondaryTypeid,
            title: this.searchKeywords,
            pageNum: this.page,
            pageSize: this.size,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.total = res.data.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
      // // 动态调整页面高度
      // this.adjustPageHeight();
    },
    getArticalEn() {
      var vm = this;
      vm.$axios
        .post("/api/enes/trans", {
          // params: {
          newsId: this.newsId,
          // },
          headers: {
            Authorization: vm.token,
          },
        })
        .then((res) => {
          vm.titleEn = res.data.data.enTitle;
          vm.contentEn = res.data.data.enContent;
          localStorage.setItem("Title", vm.titleEn);
          document.title = vm.titleEn; // 替换为实际文章标题
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    list() {
      this.content = this.articles.content;
      const currentDate = new Date(this.articles.createTime);
      const year = currentDate.getFullYear();
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const day = ("0" + currentDate.getDate()).slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      // this.primaryTypeid[i] = this.articles[i].primaryTypeid
      // this.secondaryTypeid[i] = this.articles[i].secondaryTypeid
      this.articles.fanyi = this.stripHtmlTags(this.articles.content);
      if (this.articles.content.length > this.maxTextLength) {
        const truncatedText =
          this.articles.fanyi.slice(0, this.maxTextLength) + "…";
        this.articles.truncatedContent = truncatedText; // 保存截断后的内容到新字段
      } else {
        this.articles.truncatedContent = this.articles.fanyi; // 如果没有截断，保存原始内容
      }
      this.articles.leighttitle = this.articles.title.replace(
        new RegExp(this.searchKeywords, "gi"),
        (match) => `<span style="color: var(--main-color)">${match}</span>`
      );
      this.articles.createTime = formattedDate;

      // 使用简单的字符串替换来处理src属性
      let newContent = this.content.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        (match, p1) => {
          let imgSrc = p1.indexOf("http") > -1 ? p1 : this.$utils.baseURL + p1;
          return `<img style="display: block; width: 40%; height: auto; object-fit: contain; background-size: contain; background-position: center; background-repeat: no-repeat; margin: 0.1% 30% 1% 30%; box-shadow: 5px 5px 5px #888888;" src="${imgSrc}" />`;
        }
      );
      this.articles.content = newContent;
      this.content = newContent;
      this.detailTitle = this.articles.title;
      localStorage.setItem("Title", this.detailTitle);
      document.title = this.detailTitle; // 替换为实际文章标题
      this.detailContent = this.articles.content;
      this.q = this.articles.fanyi;
      this.detailTime = this.articles.createTime;
      this.newsId = this.articles.id;
      this.collect = this.articles.isCollect;
    },
    stripHtmlTags(html) {
      return html.replace(/<[^>]*>/g, ""); // 使用正则表达式去除HTML标记
    },
  },
};
</script>


<style scoped lang="scss">
.fload {
  width: 15px;
  min-height: 75vh;
  margin-top: 5vh;
  border-radius: 15px;
  margin-bottom: 5vh;
  background-image: url("../../assets/fload.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color);
}

.limitTitLe {
  width: 50%;
  font-size: 22px;
  font-weight: bold;
  margin-top: 1%;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: #fff;
}

.buttonStyle1 {
  font-weight: bold;
  font-size: 20px;
  color: black;
  width: 250px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle2 {
  font-weight: bold;
  font-size: 20px;
  color: var(--main-color);
  width: 250px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

.subscribe {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribe.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribeDeny.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

li {
  list-style-type: none;
}

.news {
  margin-top: 1%;
  display: flex;
  margin-left: 7%;
  width: 85%;
}

.secondery {
  width: 20%;
  height: 100%;
  //border: black 1px solid;
}

.list {
  min-height: 75vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  background-color: white;
  margin-left: 2%;
  width: 100%;
  //border: black 1px solid;
}

.list1 {
  min-height: 75vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  background-color: white;
  margin: 1% 1% 60px 1%;
  width: 98%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.talk_sub {
  font-size: 5px;
  width: 50%;
  margin-top: 15vh;
  height: 30vh;
  border-radius: 10px;
  background-image: url("../../assets/none.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.talk_word {
  display: flex;
  min-height: 40px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 60%;
  margin-left: 17.5%;
  max-height: 40px;
  padding: 0px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  border: rgb(84, 82, 82) 1px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
}

.cascader {
  width: 20%;
  border-color: white !important;
}

.secondery {
  font-weight: bold;
  font-size: 20px;

  color: black;
  width: 160px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

.secondery :active {
  color: var(--main-color);
}

.talkcontent {
  min-height: 22px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 40%;
  max-height: 22px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  overflow: auto;
  margin-left: 2%;
  margin-top: 0.5%;
  //border: black solid 1px; background-color: white; /* 半透明白色，alpha值为0.5 */
}

::v-deep .el-cascader .el-input__inner:hover {
  border: none;
}

.logo {
  background-image: url("../../assets/artical.png");
  width: 30px;
  height: 30px;
  margin: 1.5% 2% 0 1.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.backlogo {
  background-image: url("../../assets/back.png");
  width: 50px;
  height: 50px;
  margin: 1.5% 2% 0 3%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.collect {
  border: none;
  background-image: url("../../assets/collect.png");
  width: 10px;
  height: 10px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.collect:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.collectReally {
  background-image: url("../../assets/collectReally.png");
  width: 14px;
  height: 14px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.translateThis {
  border: none;
  background-image: url("../../assets/trans.png");
  width: 14px;
  height: 14px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.translateThis:hover {
  transform: scale(1.2); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.translateThisReally {
  background-image: url("../../assets/trans.png");
  width: 14px;
  height: 14px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.artical_height {
  height: 90vh;
}

.artical {
  width: 90%;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 150px;
  margin-left: 5%;

  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.artical:hover {
  background-color: rgba(240, 76, 76, 0.05);
  //transform: translateY(-5px);
}

.detailContent {
  // margin-left: 7.5%;
  // width: 85%;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333333;
  min-height: 75vh;
  height: auto;
}

.custom-cascader .el-cascader-input input {
  border: none !important;
}

.custom-cascader .el-cascader-menu {
  border: 1px solid #fff !important; /* 设置下拉菜单边框为白色 */
}

.limitContent {
  margin: 1.5% 3% 0 0;
  width: 80%; /* 调整为你需要的宽度 */
  height: 80px;
  color: gray;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    text-align: left;
  }
}

.limit {
  text-align: left;
  color: gray;
  margin: 15px 5% 0 3%;
  width: 58vw; /* 调整为你需要的宽度 */
  height: 55px;
  overflow: hidden;

  p {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.limitImg {
  margin: 0.5% 3% 0 3%;
  width: 10%; /* 调整为你需要的宽度 */
  height: 65px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: 90%;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.detail1 {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: 90%;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.transborder {
  flex: 1;
  border-radius: 5px;
  width: 100%;
  margin-right: 1%;
  min-height: 75vh;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.detailBg {
  display: block;
  width: 1160px !important;
  height: 90px !important;
}

//me

.detailT {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 700;
  color: #262626;
  line-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行数
  -webkit-box-orient: vertical;
}

.detailS {
  font-size: 12px;
  color: #a1a8b3;
  line-height: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.el-button--mini {
  color: #a1a8b3;
  background-color: #f2f7ff;
}

.el-button--mini:hover {
  color: #fff;
  background: #1a79ff;
}

::v-deep .el-tabs__item {
  font-weight: 700;
  font-size: 22px;
  min-height: 30rem;
  height: 30rem;
}

.dianzan {
  width: 140px;
  height: 70px;
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid #a1a8b3;
  border-radius: 40px;
  cursor: pointer;
  background-color: transparent;
  margin-top: 30px;
  &.liked{
    border-color: var(--main-color);
    background-color: var(--main-color);
    .dianzanBg{
      background-image: url('../../assets/like.png');
    }
    .dianzanT{
      color: #fff;
    }
  }
  
}

.dianzanBg {
  display: block;
  width: 30px;
  height: 30px;
  margin: 7px auto 0;
  background-image: url('../../assets/like_grey.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.dianzanT {
  margin-top: 1px;
  font-size: 16px;
  color: #a1a8b3;
  line-height: 24px;
  text-align: center;
}


.dianzanTitle {
  margin-top: 15px;
  font-size: 14px;
  color: #8a9099;
  text-align: center;
  line-height: 22px;
}

.next {
  position: relative;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6ebf2;
  border-radius: 1px;
}

.nextBottom {
  content: "";
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #262626;
  border-radius: 1px;
  width: 50px;
}

.nextT {
  font-size: 18px;
  font-weight: 700;
}

.nextT1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover{
    color: var(--main-color);
  }
}

.nextT2 {
  margin-top: 10px;
  height: 44px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  color: #8a9099;
}

.article-box {
  margin: 20px auto;
}
.ad-box{
  margin-bottom: 20px;
}
.article-content-box {
  display: flex;
  justify-content: space-between;
  // margin-top: 30px;
  position: relative;
}
.sublevel-share {
  width: 80px;
  position: absolute;
  left: 0;
  top: 10px;
  .share-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .left {
    margin-bottom: 40px;
  }

  .custom-icon {
    margin: 20px auto 0;
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    transition: all 0.3s;
    cursor: pointer;
  }
  .new-collect {
    background-image: url("../../assets/icon_collect_default.png");
    &:hover, &.collected {
      background-image: url("../../assets/icon_collect_highlight.png");
    }
  }
  .weixin {
    background-image: url("../../assets/ico_weixin.png");
    &:hover {
      background-image: url("../../assets/ico_weixin_hover.png");
    }
  }
  .like{
    background-image: url("../../assets/like.png");
    background-color: #d9dfe7;
    background-size: 56%;
    background-position: center;
    border-radius: 100%;
    position: relative;
    &:hover, &.collected {
      background-color: var(--main-color);
    }
    &.has-num{
      background-size: 40%;
      background-position: 50% 20%;
    }
    i{
      font-style: normal;
      color: #fff;
      font-size: 12px;
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .el-divider--horizontal {
    margin: 0;
    background-color: #e6ebf2;
  }
  .el-divider__text {
    white-space: nowrap;
    font-size: 13px;
    padding: 0 6px;
  }
}
.article-content {
  margin-left: 110px;
  width: 700px;
  .el-breadcrumb {
    padding: 4px 0 0;
    .el-breadcrumb__inner a:hover{
      color: var(--main-color) !important;
    }
  }
}

.label-box {
  display: inline-block;
  height: 20px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a8b3;
  margin-right: 10px;
  background-color: #f2f7ff;
  border-radius: 10px;
  transition: all 0.3s;
  cursor: pointer;
  white-space: nowrap;
  // max-width: 8em;
  // text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }
}

//移动端
.m-info-detail-box{
  margin: 20px;
  ::v-deep .el-breadcrumb{
    color: #999;
    padding: 20px 0 0;
    .el-breadcrumb__inner a{
      color: #a1a8b3 !important;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner{
      color: #a1a8b3;
    }
  }
}
.ADHeader {
  img {
    width: 100%;
    height: 170px;
  }
  .ADT1 {
    margin-top: 20px;
    // width: 95%;
    // height: 50px;
    line-height: 24px;
    font-size: 18px;
    font-weight: bold;
    word-break: normal;
    // // margin: 10px;
    // overflow: hidden; //超出盒子宽度内容，便溢出隐藏
    // text-overflow: ellipsis; //用...省略号显示
    // display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
    // -webkit-box-orient: vertical; //从上到下垂直排列子元素
    // -webkit-line-clamp: 2; //显示的行数
  }
  .ADT2 {
    // padding-left: 10px;
    margin-top: 20px;
    font-size: 12px;
    color: #8a9099;
  }
  .ADBox {
    margin-top: 20px;
    display: flex;
    p {
      font-size: 12px;
      width: 80px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      background-color: #f2f7ff;
      color: #1a79ff;
      margin-right: 10px;
      border-radius: 10px;
    }
  }
}

.ADContent {
  margin-top: 20px;
  min-height: 26vh;
  line-height: 2;
}

.shareBox {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a79ff;
  div {
    box-shadow: 0 0 8px 0 rgba(23, 24, 26, 0.15);
    width: 90px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-right: 10px;
    img {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
  }
}
.wechat-tip{
  width: 300px;
  padding: 20px 0;
  text-align: center;
  p{
    color: var(--main-color);
    margin-bottom: 20px;
  }
}

.custom-artical-page{
  padding-bottom: 50px;
}
::v-deep .van-tabbar-item--active{
  color: initial;
}
::v-deep .van-info{
  color: var(--main-color);
  background-color: transparent;
}
::v-deep .van-tabbar-item{
  .van-icon-good-job, .van-icon-bookmark{
    color: var(--main-color);
  }
}

</style>


