<template>
    <div class="m-share-tip-page" @click="closeHandle">
        <img v-show="type == 'chat'" class="chat" src="../../assets/share_wechat.png" alt="">
        <img v-show="type == 'friend'" class="friend" src="../../assets/share_friend.png" alt="">
        <img v-show="type == 'web'" class="web" src="../../assets/share_web.png" alt="">
    </div>
</template>
<script>

export default {
    props: {
        type: String
    },
    methods: {
        closeHandle(){
            this.$emit('close')
        },
        isWxEnv(){
            let ua = navigator.userAgent.toLowerCase();
            return ua.indexOf('micromessenger') != -1;
        }
    }
}
</script>
<style scoped lang="scss">
.m-share-tip-page{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    z-index: 80;
    img{
        object-fit: contain;
    }
    .friend, .chat {
        position: fixed;
        height: 140px;
        right: -20px;
        top: 20px;
        z-index: 81;
    }
    .web {
        position: fixed;
        height: 100px;
        left: 0;
        bottom: 50px;
        z-index: 81;
    }
}
</style>